import React from 'react';
import { BasicFormWizardContext as ContextType } from 'types';

const basicFormWizardContext: ContextType = {
  formValues: {},
  formStatus: {},
  handleChange: () => {},
};

const BasicFormWizardContext = React.createContext<ContextType>(
  basicFormWizardContext
);

export default BasicFormWizardContext;
