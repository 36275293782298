import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { LogError } from 'utils/logging';
import request from 'utils/request';

let currentBatchIdRequest: Promise<string>;

/**
 * Gets a new batch id for the current user.
 */
// TODO [T1-11708]: make this retryablePromise
export function getNewLeadSubmitBatchId(): Promise<string> {
  if (currentBatchIdRequest) {
    return currentBatchIdRequest;
  }

  currentBatchIdRequest = request({
    method: 'get',
    url: `${TRIAD_PROXY_ROUTE}/getNewLeadSubmitBatch`,
  })
    .then(({ batchId }: { batchId: string }) => {
      if (!batchId) {
        LogError(
          `We could not get a batch id. What do we do now? Default to some batch id given sessionId?`
        );
      }

      return batchId;
    })
    .catch(() => {
      return {
        batchId: '',
      };
    });

  return currentBatchIdRequest;
}
