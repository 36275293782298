import React, { useCallback, useContext, useEffect } from 'react';
import { SelectionCard } from 'components/form-wizards/micro-portal/SelectionCard/SelectionCard';
import SchoolLogo from 'components/form-wizards/micro-portal/SchoolLogo';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { MultiSchoolSelectionState, MultiSchoolSelectProps } from 'types';
import { useMultiSchoolSelect } from './useMultiSchoolSelect';
import MultiSchoolSelectContext from './multiSchoolSelectContext';

/**
 * Renders a list of SelectionCards based on the provided options.
 * Additionally renders a "cart" that displays the schools that have been submitted.
 */
export function MultiSchoolSelect(
  props: MultiSchoolSelectProps
): React.JSX.Element {
  const { options, name, error } = props;
  const { handleChange } = useContext(BasicFormWizardContext);

  const updateMultiSchoolSelectFormValue = useCallback(
    (multiSchoolSelectFormValue: MultiSchoolSelectionState) => {
      handleChange({ value: multiSchoolSelectFormValue }, { name });
    },
    [name]
  );

  const multiSchoolSelectState = useMultiSchoolSelect(
    options,
    name,
    updateMultiSchoolSelectFormValue
  );

  const { state, handleNextClick } = multiSchoolSelectState;

  const shouldShowCart = Object.values(state).some(
    (school) => school.isSubmitted
  );

  useEffect(() => {
    if (error) {
      handleNextClick();
    }
  }, [error]);

  return (
    <MultiSchoolSelectContext.Provider value={multiSchoolSelectState}>
      <div className="multiSchoolSelect">
        <div className="multiSchoolSelect__inner">
          <div className="multiSchoolSelect__title">
            Feel free to adjust your selections
          </div>
          <ul className="multiSchoolSelect__list">
            {Object.values(state).map((school) => (
              <li
                className="multiSchoolSelect__listItem"
                key={school.schoolInfo.id}
              >
                <SelectionCard id={school.schoolInfo.id} />
              </li>
            ))}
          </ul>
        </div>
        <div
          className={
            shouldShowCart
              ? 'multiSchoolSelect__cart multiSchoolSelect__cart--hasItems'
              : 'multiSchoolSelect__cart'
          }
        >
          <div className="multiSchoolSelect__cartHeading">
            Congratulations, you & rsquo;ve been submitted to these schools:
          </div>
          <div className="multiSchoolSelect__cartSubHeading">
            A representative will reach out shortly
          </div>
          <ul className="multiSchoolSelect__cartList">
            {Object.keys(state)
              .filter((schoolId) => state[schoolId].isSubmitted)
              .map((schoolId) => (
                <li
                  className="multiSchoolSelect__cartItem"
                  key={`submittedSchool_${schoolId}`}
                >
                  <SchoolLogo
                    logo={state[schoolId].schoolInfo.schoolLogo}
                    className="selectionCard__logoImg"
                    alt={`${state[schoolId].schoolInfo.label} logo`}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </MultiSchoolSelectContext.Provider>
  );
}
