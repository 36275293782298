/* eslint-disable prefer-promise-reject-errors */
import { useEffect, useReducer } from 'react';
import { categoryFieldIds, FIELD_NAMES, QUESTION_IDS } from 'consts';
import { LogError } from 'utils/logging';
import Router from 'next/router';
import getVisibleQuestions from 'components/form-wizards/basic-form/getVisibleQuestions';
import validateStep from 'hooks/custom/forms/basic-wizard/utils/validateStep';
import { logProgress, submitLead } from 'app-requests/triadmsRequests';
import {
  getValidFormValues,
  isNextStepDisabled,
  isStepSkipable,
  removeNoValuesFromForm,
} from 'utils/formValuesUtils';
import {
  FLOODLIGHT_EVENTS,
  trackGAConversion,
  trackFloodlightActivity,
  trackUserMoveBack,
} from 'utils/trackingFunctions';
import preFormSubmitChecks from 'components/form-wizards/basic-form/preFormSubmitChecks';
import {
  applyPreFills,
  canResumeForm,
  generatePercentComplete,
} from 'components/form-wizards/basic-form/utils';
import { initJornaya } from 'utils/thirdPartyScripts';
import { COUNTERS, incrementCounter } from 'utils/counters';
import { getQuestionOptions } from 'app-requests/triadms-apis/getQuestionOptions';
import processDynamicOptions from './utils/processDynamicOptions';
import { initialState, reducer } from './reducer';
import shouldShortCircuitFormUpdate from './utils/shouldShortCircuitFormUpdate';
import getLastQuestionAnswered from './utils/getLastQuestionAnswered';
import updateFormValue from './reducer-functions/updateFormValue';

const { SUBJECT_0F_INTEREST } = QUESTION_IDS;
const { EMAIL } = FIELD_NAMES;

export default function useBasicFormWizardHandler(
  formConfigs,
  currentBreakpoint,
  sessionFormValues,
  linkedSessionFormValues,
  metaData = {}
) {
  // TODO: [T1-9618] Why are we not adding formConfigs directly to the initialization.
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    uuid: `${Math.random()}_${Date.now()}`,
  });

  // we always need to request the subjects for this micro site
  useEffect(() => {
    /**
     * In the past we only wanted to initJornaya when a user sees PII
     * but we may create a new form as part of a multi form experience
     * that has no PII but is a continuation of another form
     */
    if (formConfigs.shouldInitJornaya) {
      initJornaya();
    }

    dispatch({ type: 'SET_STATUS', isProcessing: true });

    const optionRequest = formConfigs.onOptionsRequest || getQuestionOptions;

    optionRequest(
      { requestedOptions: [SUBJECT_0F_INTEREST], isOnload: true },
      formConfigs.schoolCode,
      formConfigs.variant
    ).then((questionOptionsMap) => {
      dispatch({
        type: 'SET_DYNAMIC_OPTIONS',
        dynamicOptions: questionOptionsMap,
      });

      dispatch({ type: 'SET_STATUS', isProcessing: false });
      return questionOptionsMap;
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'INIT',
      formConfigs,
    });
  }, [formConfigs.variant]);

  const storeAndLogProgress = (formValues) => {
    const lastQuestionAnswered = getLastQuestionAnswered(
      state.currentQuestions
    );

    !formConfigs.disableLogging &&
      (formConfigs.onLogProgress || logProgress)(
        formValues,
        state.fieldNameMap,
        formConfigs,
        linkedSessionFormValues,
        lastQuestionAnswered
      );

    if (state.currentQuestions?.some(({ name }) => name === EMAIL)) {
      trackFloodlightActivity(
        FLOODLIGHT_EVENTS.PROFILE_CREATED,
        metaData.floodlightActivityValues,
        metaData.floodLightActivityFilters
      );
    }

    return formValues;
  };

  function handleSuccessRedirect(successRedirectUrl, formValues) {
    if (!successRedirectUrl) {
      return;
    }

    const urlPath = Router?.query?.degree || 'default';

    const pageNameSegs = successRedirectUrl.split('/');
    const pageName = pageNameSegs[pageNameSegs.length - 1];

    Router.push(
      {
        pathname: `/${urlPath}/landing/form/thankyou`,
        query: {
          redirectUrl: successRedirectUrl,
          pages: pageName,
          userName: formValues[FIELD_NAMES.FIRST_NAME],
        },
      },
      `/${urlPath}/landing/form/thankyou`
    );
  }

  /**
   * @param {Object} formSubmitResponse
   * @returns formSubmitResponse
   */
  function handleConversionTracking(formSubmitResponse = {}) {
    const { shouldTrackConversion, summary, leadsSubmittedFor } =
      formSubmitResponse;

    if (!shouldTrackConversion) {
      return formSubmitResponse;
    }

    try {
      // for multi submit
      const resultSummary = summary || {};
      // for single submit
      const [submittedUser] = leadsSubmittedFor || [];
      const revenue = resultSummary.revenue || submittedUser?.revenue;
      const adjustedRevenue =
        resultSummary.adjustedRevenue || submittedUser?.adjustedRevenue;
      const floodlightValues = metaData?.floodlightActivityValues; // should always be present, from server
      const userFilterValues = metaData?.floodLightActivityFilters; // getUserSession, from client side

      trackGAConversion(revenue, adjustedRevenue);

      if (floodlightValues && userFilterValues) {
        trackFloodlightActivity(
          FLOODLIGHT_EVENTS.PROFILE_PAYABLE_USER,
          floodlightValues,
          userFilterValues,
          {
            revenue,
            adjustedRevenue,
            schoolCode: formConfigs.schoolCode,
          }
        );
      } else {
        // Do we want to know if this was not tracked?
      }
    } catch (error) {
      LogError(`Failed to track Floodlight Activity: ${error.message}`);
    }

    return formSubmitResponse;
  }

  /**
   * @summary Called when user clicks continue. if its the last step it will submit the form
   */
  const moveToNextStep = (formValues, isNonUserAction) => {
    const canResume = canResumeForm(
      state,
      sessionFormValues,
      linkedSessionFormValues
    );
    const alreadyResumed = state.formStatus.isResumeSessionAcknowledged;

    if (canResume && !isNonUserAction && !alreadyResumed) {
      return dispatch({ type: 'SET_STATUS', isFormResumeEligible: true });
    }

    if (!state.isLastStep) {
      return dispatch({ type: 'MOVE_TO_NEXT_STEP', isNonUserAction });
    }

    const cleanForm = getValidFormValues({ ...state, formValues });
    preFormSubmitChecks(cleanForm, state);

    // submit the lead

    if (formConfigs.customSubmitHandler) {
      return formConfigs
        .customSubmitHandler(
          cleanForm,
          state.fieldNameMap,
          formConfigs,
          // TODO: [T1-9871] refactor to just send down state. We have gotten to the point that we need a lot of state info
          state
        )
        ?.then(handleConversionTracking);
    }
    // TODO: [T1-9871] submitLead and customSubmitHandler should have the same signature
    return submitLead(
      cleanForm,
      state.fieldNameMap,
      { ...formConfigs, leadEvalToken: state?.formStatus?.leadEvalToken },
      metaData
    )
      .then(handleConversionTracking)
      .then(() => {
        dispatch({ type: 'MOVE_TO_THANK_YOU' });
        handleSuccessRedirect(formConfigs.successRedirectUrl, formValues);
      });
  };

  /**
   *
   * @param {*} errors
   */
  const handleSubmitError = (error) => {
    if (error.isFormError) {
      return dispatch({
        type: 'SET_STATUS',
        errors: error,
      });
    }

    dispatch({
      type: 'SET_STATUS',
      formError: error.message,
    });
    // API or other uncaught
    return LogError(`Form handleSubmitError: ${error.message}`);
  };

  /**
   * @param {String} field - the field name
   * @param {String} error - the error message to set
   */
  const setFieldError = (field, error) => {
    return dispatch({
      type: 'SET_STATUS',
      errors: { ...state.formStatus.errors, [field]: error },
    });
  };

  const setStatus = (nextStatus) => {
    return dispatch({
      type: 'SET_STATUS',
      ...nextStatus,
    });
  };

  const handleBack = (shouldTrack = true) => {
    shouldTrack && trackUserMoveBack(state.currentQuestions);
    dispatch({
      type: 'MOVE_BACK',
    });
  };

  /**
   * @summary The main submit function that will run validations and logging
   * @param {Object} formValues current user form values
   * @param {Bool} isNonUserAction it could be some external force is submitting a step
   */
  const _handleSubmit = (formValues, isNonUserAction) => {
    if (isNextStepDisabled(state)) {
      dispatch({
        type: 'SET_STATUS',
        isLoadingActive: true,
        willAutoAdvance: false,
      });
      return Promise.resolve({});
    }

    dispatch({
      type: 'SET_STATUS',
      isSubmitting: true,
      errors: {},
      formError: '',
      isLoadingActive: state.isLastStep,
    });

    // We should not store the updated values if it was not the user who selected them
    const logger =
      isNonUserAction && !formConfigs.disableLogging
        ? (v) => v
        : storeAndLogProgress;

    return validateStep(state.currentQuestions, formValues)
      .then(logger)
      .then(() => moveToNextStep(formValues, isNonUserAction))
      .catch(handleSubmitError)
      .finally(() => {
        dispatch({
          type: 'SET_STATUS',
          isSubmitting: false,
          isLoadingActive: false,
        });
      });
  };

  /**
   * @summary The main submit function that will run validations and logging
   */
  const handleSubmit = () => {
    _handleSubmit(state.formValues);
  };

  // TODO: [T1-9619] We have not coverage for handleChange. This does most of the work so we need to add tests
  /**
   * @param {String|Object} value - value of the field
   * @param {Object} arg.name - name of the field
   */
  const handleChange = (value, { name, isNonUserAction }) => {
    const { isNextStepDynamic } = state;
    const action = {
      type: 'SET_FIELD_VALUE',
      name,
      value,
      isNonUserAction,
    };

    // update UI
    dispatch(action);
    const predictedUpdates = updateFormValue(state, action);
    // TODO: [T1-9648] Update onChange to remove applyPreFills as we can pull this from predictedUpdates above
    const { updatedForm } = applyPreFills(state, state.formValues, {
      [action.name]: action.value,
    });

    formConfigs.onChange && formConfigs.onChange(action, updatedForm);

    if (shouldShortCircuitFormUpdate({ action })) {
      return;
    }

    // on each change we may want to push the user to the next possible step. This is dependent on many thing below
    const visibleQuestions = getVisibleQuestions(
      predictedUpdates.currentQuestions,
      {
        formValues: predictedUpdates.formValues,
        formStatus: predictedUpdates.formStatus,
      }
    );

    const _isStepSkippable = formConfigs.handleAutoAdvance || isStepSkipable;

    const shouldSkip =
      !isNonUserAction &&
      _isStepSkippable(visibleQuestions, updatedForm, currentBreakpoint, {
        ...state,
        isNonUserAction,
      });

    const isCurrentStepSkipable = !visibleQuestions.length || shouldSkip;

    const shouldDeferRender = isNextStepDynamic;

    // Its possible the next step may not be show because we need to load options from backend
    if (isCurrentStepSkipable && !shouldDeferRender) {
      _handleSubmit(updatedForm, isNonUserAction);
    } else if (isCurrentStepSkipable && shouldDeferRender) {
      dispatch({
        type: 'SET_STATUS',
        isLoadingActive: true,
        willAutoAdvance: true,
      });
    }

    // Also on each change to the form values we need to request options from our backend
    try {
      processDynamicOptions(updatedForm, state, dispatch, name, {
        onOptionsRequest: formConfigs.onOptionsRequest,
      }).then(() => {
        // Note that processDynamicOptions could have marked questions as skipped. Should not cause an issue because _handleSubmit will navigate user correctly
        if (isCurrentStepSkipable && shouldDeferRender) {
          _handleSubmit(updatedForm, isNonUserAction);
        }
      });
    } catch (error) {
      LogError(`Failed To processDynamicOptions: ${error.message}`);
    }
  };

  /**
   *
   * @param {Object} newFormValues the form values to update the form with { [fieldName]: value }
   */
  const handleResumeForm = (shouldContinue) => {
    dispatch({
      type: 'RESUME_FORM',
      shouldSkipSteps: shouldContinue,
      shouldStopAtQuestions: categoryFieldIds,
      isResumeSessionAcknowledged: true,
    });
  };

  /**
   * @summary simply reset the form, but not its values
   */
  function initForm() {
    dispatch({
      type: 'INIT',
      formConfigs,
    });
  }

  /**
   * @summary it could be that the user is on a step they should not be, such as a linked session value came it but they should not see that step. In this case we skip them to the next step they should be able to see
   */
  function reconcileStep(updatedState) {
    const _state = updatedState || state;
    const visibleQuestions = getVisibleQuestions(
      _state.currentQuestions,
      _state
    );

    if (!visibleQuestions.length && !_state.formStatus.isInitializing) {
      moveToNextStep(_state.formValues, true);
      incrementCounter(COUNTERS.CURRENT_STEP_ADVANCED);
    }
  }

  /**
   * @summary update any fields of values in the form
   */
  function updateFormValues(newFormValues, updatedFormStatus = {}) {
    const updatedForm = {
      ...newFormValues,
      ...removeNoValuesFromForm(state.formValues),
    };

    dispatch({
      type: 'UPDATE_FORM_VALUES',
      formValues: updatedForm,
      formStatus: updatedFormStatus,
    });

    try {
      const args = [
        updatedForm,
        state,
        dispatch,
        undefined,
        {
          onOptionsRequest: formConfigs.onOptionsRequest,
        },
      ];

      processDynamicOptions(...args).then(({ updatedState }) => {
        reconcileStep(updatedState);
      });
    } catch (error) {
      LogError(`Failed To processDynamicOptions: ${error.message}`);
    }
  }

  /**
   * @summary use this if you want to update all field values and reprocess the form
   * TODO: Write Unit tests to ensure this works correctly
   * @deprecated DON'T USE THIS
   */
  function reprocessForm(newFormValues) {
    dispatch({
      type: 'UPDATE_FORM_VALUES',
      formValues: newFormValues,
    });

    try {
      // TODO: why can we not use updateFormValues, or pass in no name to reprocess the whole form?
      Object.keys(newFormValues).forEach((name) =>
        processDynamicOptions(newFormValues, state, dispatch, name, {
          onOptionsRequest: formConfigs.onOptionsRequest,
        })
      );
    } catch (error) {
      LogError(
        `Failed To processDynamicOptions when running reprocessForm: ${error.message}`
      );
    }
  }

  /**
   * @summary !IMPORTANT! Only use this if you need to clear values, use updateFormValues when you need to set new values
   */
  function setFormValues(newFormValues) {
    dispatch({
      type: 'UPDATE_FORM_VALUES',
      formValues: newFormValues,
    });
  }

  function setFormFieldValidationStatus(fieldNameMap) {
    dispatch({
      type: 'SET_FIELD_VALIDATION_STATUS',
      fieldNameMap,
    });
  }

  // TODO: Reactor this to expose formState and actions
  return {
    ...state,
    percentComplete: generatePercentComplete(state),
    handleSubmit,
    handleChange,
    handleBack,
    handleResumeForm,
    initForm,
    updateFormValues,
    setFormValues,
    setFieldError,
    reconcileStep,
    reprocessForm,
    actions: {
      setStatus,
      setFormFieldValidationStatus,
    },
  };
}
