/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import Rating from 'components/base/rating/Rating';
import ResultCardProgram from 'components/sections/lists/ClickPortalResults/result-card/ResultCardProgram';
import CloseBtn from 'components/base/closeBtn/CloseBtn';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import SchoolLogo from '../SchoolLogo';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardDetailsProps {
  id: string;
}

export function SelectionCardDetails(
  props: SelectionCardDetailsProps
): React.JSX.Element {
  const { id } = props;
  const { handleCloseCard, handleProgramChange, state } = useContext(
    MultiSchoolSelectContext
  );

  const {
    isSelected,
    program,
    schoolInfo: {
      degreeList,
      label,
      description,
      highlights,
      matchingProgramOptions,
      rating,
      schoolLogo,
      terms,
    },
  } = state[id];

  return (
    <div className="selectionCard__back">
      <span className="selectionCard__closeBtn">
        <CloseBtn onClick={() => handleCloseCard(id)} />
      </span>
      <div className="selectionCard__body">
        <div className="selectionCard__meta">
          <div className="selectionCard__logo">
            <SchoolLogo
              logo={schoolLogo}
              className="selectionCard__logoImg"
              alt={`${label} logo`}
            />
          </div>
          <div className="selectionCard__rating">
            <div className="selectionCard__ratingLabel">
              Editor&#39;s Rating
            </div>
            <Rating rating={rating} id={`rating${label}`} />
          </div>
        </div>
        <div className="selectionCard__content">
          <h4 className="selectionCard__title">{label}</h4>
          <div className="selectionCard__formCtrl">
            <div className="selectionCard__formLabel">
              <label htmlFor="selectCardSelection">Choose your program</label>
            </div>
            <div className="selectionCard__formField">
              <ReactSelect
                options={matchingProgramOptions}
                value={program}
                onChange={(selectedProgram) =>
                  handleProgramChange(id, selectedProgram)
                }
                isDisabled={!isSelected}
                name="selectCardSelection"
              />
            </div>
          </div>
          <div className="selectionCard__terms">
            <Wysiwyg content={terms} />
          </div>
        </div>
        <div className="selectionCard__actions">
          <SelectionCardActions id={id} showSelect={false} showSubmit />
        </div>
        <div className="selectionCard__schoolInfo">
          <div className="selectionCard__infoSection selectionCard__infoSection--description">
            <div className="selectionCard__infoSectionHeader">
              <div className="selectionCard__infoSectionHeaderText">
                Description
              </div>
            </div>
            <Wysiwyg content={description} />
          </div>
          <div className="selectionCard__infoSection selectionCard__infoSection--highlights">
            <div className="selectionCard__infoSectionHeader">
              <div className="selectionCard__infoSectionHeaderText">
                Highlights
              </div>
            </div>
            <Wysiwyg content={highlights} />
          </div>
          <div className="selectionCard__infoSection selectionCard__infoSection--results">
            <div className="selectionCard__infoSectionHeader">
              <div className="selectionCard__infoSectionHeaderText">
                Programs
              </div>
            </div>
            <div className="resultList">
              <div className="resultList__groups">
                {degreeList?.map((degree) => (
                  <div className="resultList__group" key={degree.value}>
                    <div className="resultList__groupTitle">{degree.label}</div>
                    <ul className="resultList__items">
                      {degree.programs?.map((degreeProgram) => (
                        <ResultCardProgram
                          key={degreeProgram.label}
                          item={degreeProgram}
                          isProgramView
                        />
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
