import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import React, { useContext } from 'react';
import { UseMultiSchoolSelect } from 'types';

interface SelectionCardActionsProps {
  id: string;
  showSelect: boolean;
  showSubmit: boolean;
}
export function SelectionCardActions(
  props: SelectionCardActionsProps
): React.JSX.Element {
  const { id, showSelect, showSubmit } = props;
  const { state, handleSelectSchool, handleSkipSchool, handleAcceptTerms } =
    useContext<UseMultiSchoolSelect>(MultiSchoolSelectContext);

  if (state.isSubmitted) {
    return (
      <div className="selectionCard__submitted">
        <div className="selectionCard__submittedIcon">
          <svg
            enableBackground="new 0 0 32 32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m27 4-15 15-7-7-5 5 12 12 20-20z" />
          </svg>
        </div>
        <span className="selectionCard__submittedText">Submitted</span>
      </div>
    );
  }

  return (
    <div className="selectionCardActions">
      {showSelect && (
        <button
          className="selectionCardActions__btn selectionCardActions__btn--select "
          type="button"
          onClick={() => handleSelectSchool(id)}
        >
          Request Info
        </button>
      )}

      {showSubmit && (
        <>
          <button
            className="selectionCardActions__btn selectionCardActions__btn--submit"
            type="button"
            onClick={() => handleAcceptTerms(id)}
          >
            Submit
          </button>
          <button
            className="selectionCardActions__btn selectionCardActions__btn--skip"
            type="button"
            onClick={() => handleSkipSchool(id)}
          >
            Skip
          </button>
        </>
      )}
    </div>
  );
}
