import { FIELD_NAMES } from 'consts';

// ----------------------- //
// ------- Global -------- //
// ----------------------- //
export interface FormValues {}
export interface FormStatus {}

export interface SelectFieldOption {
  value: string;
  label: string;
}

export interface FormField<T> {
  name: string;
  value: T;
}

export interface Program {
  value: string;
  label: string;
}

export interface Degree {
  value: string;
  label: string;
  programs: Program[];
}

export interface School {
  degreeList: Degree[];
  description: string;
  featuredImage: { mobile: string; desktop: string };
  highlights: string;
  id: string;
  label: string;
  matchingProgramOptions: { value: string; label: string }[];
  selectedProgramLabel: string;
  rating: number;
  schoolLogo: { mobile: string; desktop: string };
  terms: string;
}

// ----------------------- //
// -- Field Validations -- //
// ----------------------- //
export interface FieldValidationArguments<T> {
  value: T;
  name: string;
  errorMessage?: string;
  formValues?: Record<FIELD_NAMES, FormField<T>>;
}

export interface FieldValidationResult {
  [FIELD_NAMES: string]: string | null;
}

export type FieldValidationFunctionSignature<T> = (
  args: FieldValidationArguments<T>
) => FieldValidationResult | Promise<FieldValidationResult>;

// ----------------------- //
// ------- States -------- //
// ----------------------- //
export interface SchoolSelectionState {
  isSelected: boolean;
  isExpanded: boolean;
  isAccepted: boolean;
  isSkipped: boolean;
  isShowingDetails: boolean;
  program: string | null;
  isSubmitted: boolean;
  schoolInfo: School;
}

export type MultiSchoolSelectionState = Record<string, SchoolSelectionState>;

export enum MultiSchoolSelectActionTypes {
  EXPAND_CARD = 'EXPAND_CARD',
  SKIP_CARD = 'SKIP_CARD',
  ACCEPT_TERMS = 'ACCEPT_TERMS',
  EXPAND_NEXT_CARD = 'EXPAND_NEXT_CARD',
  TOGGLE_DETAILS = 'TOGGLE_DETAILS',
  CLOSE_CARD = 'CLOSE_CARD',
  UPDATE_PROGRAM = 'UPDATE_PROGRAM',
  INITIALIZE = 'INITIALIZE',
}

export interface MultiSchoolSelectAction {
  type: MultiSchoolSelectActionTypes;
  payload: {
    id?: string;
    program?: string;
    schools?: School[];
    handleFormFieldChange?: (
      updatedFieldValue: MultiSchoolSelectionState
    ) => void;
  };
}

// ----------------------- //
// -------- Hooks -------- //
// ----------------------- //
export interface UseMultiSchoolSelect {
  state: MultiSchoolSelectionState;
  handleSelectSchool: (id: string) => void;
  handleSkipSchool: (id: string) => void;
  handleAcceptTerms: (id: string) => void;
  handleNextClick: () => void;
  handleToggleDetails: (id: string) => void;
  handleCloseCard: (id: string) => void;
  handleProgramChange: (id: string, program: string) => void;
  initializeState: (school: School[]) => void;
}

// ----------------------- //
// ------ Contexts ------- //
// ----------------------- //
export interface BasicFormWizardContext {
  formValues: FormValues;
  formStatus: FormStatus;
  handleChange: <T>(fieldValue: T, event: { name: FIELD_NAMES }) => void;
}

// ----------------------- //
// ---- API Interfaces ---- //
// ----------------------- //

export interface getQuestionOptionsPayload {
  maxSchools?: number;
  leadEvalToken?: string;
  pageTaxonomyValues?: {
    degrees: string;
    parentCategories: string;
    categories: string;
  };
  questionReplies: {
    questionAnswer: string;
    questionId: string;
  }[];
}

export interface getQuestionOptionsParsedResponse {
  [key: string]: {
    questionId: string;
    options: SelectFieldOption[] | School[];
  };
}

export interface SchoolOptionAPIResponse {
  HasFeaturedPrograms: boolean;
  IsOffsiteConversion: boolean;
  MatchingCategory: string;
  destinationUrl: string;
  filteredProgramCount: number;
  highlights: string;
  impressionGuid: string;
  label: string;
  learningEnvironment: string;
  programCount: number;
  programs?: {
    ProgramList?: {
      DegreeTypeGuid: string;
      categoryPriority: number;
      destinationUrl: string;
      label: string;
      matchesFilterTag: boolean;
      shortDesc: string;
      value: string;
    }[];
    value: string;
    label: string;
  }[];
  rating: number;
  schoolCode: string;
  schoolDesc?: string[];
  schoolImages?:
    | {
        Desktop?: { imageUrl: string };
        Mobile?: { imageUrl: string };
      }
    | undefined;
  selectedProgram?: {
    DegreeTypeGuid: string;
    categoryPriority: number;
    destinationUrl: string;
    label: string;
    matchesFilterTag: boolean;
    shortDesc: string;
    value: string;
  };
  value: string;
}

export interface getQuestionOptionsRawResponse {
  Listings?: SchoolOptionAPIResponse[];
  questions: { questionId: string; options: SelectFieldOption[] }[];
}

// ----------------------- //
// ------ Props ------- //
// ----------------------- //
export interface MultiSchoolSelectProps {
  options: School[];
  name: FIELD_NAMES;
  error?: string;
}
