import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from 'hooks/contexts/GlobalContext';
import HeroWithForm from 'components/sections/heros/heroWithForm/HeroWithForm';
import { QUESTION_IDS } from 'consts';
import { getQuestionOptions } from 'app-requests/triadms-apis/getQuestionOptions';
import HeroDefault from './heroDefault/HeroDefault';
import HeroShort from './heroShort/HeroShort';

const { SUBJECT_0F_INTEREST } = QUESTION_IDS;

export const HERO_TYPES = {
  default: HeroDefault,
  withFormWizard: HeroWithForm,
  short: HeroShort,
};

export default function Hero({ heroType, ...props }) {
  const HeroComponent = HERO_TYPES[heroType];
  const heroRef = useRef(null);
  const { actions, questionnaire } = useContext(GlobalContext);

  const { schoolCode, variant } = questionnaire || {};

  useEffect(() => {
    // For Heros with forms, we need to pre-fetch the data before the form is rendered
    if (schoolCode && HeroComponent !== HERO_TYPES.withFormWizard) {
      getQuestionOptions(
        { requestedOptions: [SUBJECT_0F_INTEREST] },
        schoolCode,
        variant
      );
    }
  }, [HeroComponent, schoolCode, variant]);

  useEffect(() => {
    if (heroRef) {
      actions.updateGlobalRefs({ heroRef });
    }
    return () => {
      actions.updateGlobalRefs({ heroRef: null });
    };
  }, [heroRef.current]);

  if (!HeroComponent) {
    return <div>{`NO HERO COMPONENT MAPPED YET FOR ${heroType}`}</div>;
  }

  return <HeroComponent {...props} heroRef={heroRef} />;
}

Hero.propTypes = {
  heroType: PropTypes.string.isRequired,
};
