// TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
import get from 'lodash/get';
import { MICRO_PORTAL_ENDPOINTS, QUESTION_IDS } from 'consts';
import { isQuestionOptionsCacheable } from 'utils/generalUtils';
import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import request from 'utils/request';
import { questionsToMap } from 'utils/formValuesUtils';
import {
  getQuestionOptionsPayload,
  getQuestionOptionsParsedResponse,
  School,
  SchoolOptionAPIResponse,
  getQuestionOptionsRawResponse,
} from 'types';

const { SUBJECT_0F_INTEREST, MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION } =
  QUESTION_IDS;

/**
 * Maps the raw school options response from the API to the shape required by the form
 */
function transformSchoolListings(schools: SchoolOptionAPIResponse[]): School[] {
  return schools.map((school) => ({
    degreeList:
      school.programs?.map((degree) => ({
        label: degree.label,
        value: degree.value,
        programs:
          degree.ProgramList?.map((program) => ({
            label: program.label,
            value: program.value,
          })) || [],
      })) || [],
    description: school?.schoolDesc?.[0] || '',
    featuredImage: {
      mobile: '',
      desktop: '',
    },
    highlights: school.highlights || '',
    id: school.value,
    label: school.label,
    selectedProgramLabel: school.selectedProgram?.label || '',
    matchingProgramOptions: [],
    rating: school.rating,
    schoolLogo: {
      mobile: school.schoolImages?.Mobile?.imageUrl || '',
      desktop: school.schoolImages?.Desktop?.imageUrl || '',
    },
    terms: '',
  }));
}

/**
 * @summary given the current state of the form and the current school we may need to get options for a question
 */
export function getQuestionOptions(
  payload: getQuestionOptionsPayload,
  schoolCode: string,
  variant: string,
  endpoint = '/questionOption'
): getQuestionOptionsParsedResponse {
  const replies = get(payload, 'questionReplies', []).map(
    ({ questionAnswer, questionId }) => ({
      questionAnswer,
      questionId,
    })
  );

  const neededOptions = get(payload, 'requestedOptions', []);
  const endpointUrl = `${TRIAD_PROXY_ROUTE}${endpoint}`;
  let cacheKey = '';

  // if options request is just for subject then cache
  if (isQuestionOptionsCacheable(neededOptions)) {
    // not taking variant into account here because we are only caching for subject which is the same on all variants
    cacheKey = `${schoolCode}_questionOptions_${SUBJECT_0F_INTEREST}`;
  }

  // BUG: [T1-9320] If API returns 0 results we should not cache.
  return request({
    method: 'post',
    url: endpointUrl,
    cacheKey,
    body: {
      schoolCode,
      maxSchools: payload.maxSchools,
      pageTaxonomyValues: payload.pageTaxonomyValues,
      leadEvalToken: payload.leadEvalToken,
      templateName: variant,
      requestedOptions: neededOptions,
      questionReplies: replies,
    },
  })
    .then(({ questions, Listings }: getQuestionOptionsRawResponse) => {
      if (
        endpointUrl.includes(MICRO_PORTAL_ENDPOINTS.SCHOOL_OPTIONS_ENDPOINT) &&
        Listings
      ) {
        return {
          [MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]: {
            questionId: MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION,
            options: transformSchoolListings(Listings),
          },
        };
      }

      return questionsToMap(questions);
    })
    .catch((error: Error) => {
      throw error;
    });
}
