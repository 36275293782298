import React from 'react';
import { UseMultiSchoolSelect } from 'types';

function nonInitializeFunction(): never {
  throw new Error('Not initialized');
}

const initialContextState: UseMultiSchoolSelect = {
  state: {},
  handleSelectSchool: nonInitializeFunction,
  handleSkipSchool: nonInitializeFunction,
  handleProgramChange: nonInitializeFunction,
  handleAcceptTerms: nonInitializeFunction,
  handleToggleDetails: nonInitializeFunction,
  handleNextClick: nonInitializeFunction,
  handleCloseCard: nonInitializeFunction,
  initializeState: nonInitializeFunction,
};

const MultiSchoolSelectContext =
  React.createContext<UseMultiSchoolSelect>(initialContextState);

export default MultiSchoolSelectContext;
