import { useRouter } from 'next/router';
import {
  getMicroPortalLeadSubmitResults,
  logProgress,
  submitLead,
} from 'app-requests/triadmsRequests';
import { getNewLeadSubmitBatchId } from 'app-requests/triadms-apis/getNewLeadSubmitBatchId';
import promiseDebounce from 'utils/promiseDebounce';
import {
  MICRO_PORTAL_PRIMARY_FORM_TID,
  MICRO_PORTAL_SECONDARY_FORM_TID,
  QUESTION_IDS,
  MICRO_PORTAL_ENDPOINTS,
} from 'consts';
import { retryablePromise } from 'utils/generalUtils';
import { LogError } from 'utils/logging';
import {
  getPollingHelper,
  requestWithTransaction,
} from 'utils/requestWithTransaction';
import { getQuestionOptions } from 'app-requests/triadms-apis/getQuestionOptions';
import { useContext } from 'react';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import GlobalContext from 'hooks/contexts/GlobalContext';

const additionalLeadsPageUri = '/portal/results';
const clickPortalLikePageUri = '/portal/search';
const transactionKey = 'batchTransactionId';

const { MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION } = QUESTION_IDS;

export const FORM_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const LEAD_SUBMIT_ENDPOINT_MAP = {
  [FORM_TYPES.PRIMARY]: '/microportal/MPLeadSubmitQueuePush',
  [FORM_TYPES.SECONDARY]: '/microportal/MPAdditionalLeadSubmitQueuePush',
};

const FORM_TID_MAP = {
  [FORM_TYPES.PRIMARY]: MICRO_PORTAL_PRIMARY_FORM_TID,
  [FORM_TYPES.SECONDARY]: MICRO_PORTAL_SECONDARY_FORM_TID,
};

const debouncedRequestOptions = promiseDebounce(getQuestionOptions, 600);

const debouncedSchoolSelectionRequestOptions = promiseDebounce(
  (...args) =>
    retryablePromise(() => getQuestionOptions(...args), {
      maxRetryAttempts: 3,
      waitTimeBetweenFails: 2000,
    }).catch((error) => {
      LogError('School Selection Api on the micro portal has failed', {
        error,
      });
      return { [MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]: { options: [] } };
    }),
  600
);

/**
 * Custom hook for handling form submission and related functionality in the micro portal.
 *
 * @param {Function} options.updateMicroPortalData - Function to update micro portal data.
 * @param {Object} options.primaryFormSubmitPollingHelper - Helper for polling primary form submit results. This will be the return value of requestWithTransaction
 * @param {Object} options.secondaryFormSubmitPollingHelper - Helper for polling secondary form submit results. This will be the return value of requestWithTransaction
 * @param {number} options.onSubmitPushMsDelay - Delay in milliseconds before pushing to a new page after form submission.
 * @returns {Object} - Object containing functions for handling form submission and related functionality.
 * @see requestWithTransaction.js for more information on the polling helpers. The getPollingHelper function is used to create the helpers.
 */
export default function useMicroPortalFormHandler({
  updateMicroPortalData,
  primaryFormSubmitPollingHelper,
  secondaryFormSubmitPollingHelper,
  onSubmitPushMsDelay = 3000,
} = {}) {
  const router = useRouter();
  const { formStatus } = useContext(BasicFormWizardContext);
  const { taxonomyValues } = useContext(GlobalContext);

  /**
   * @summary shouldPollMoreLeadResults for lead submit results
   * @param {Object} resultsResponse - the response from the lead submit results endpoint
   * @param {String} formType - the type of form we are polling results for
   * @returns {Boolean} - true if we should keep polling
   */
  function shouldPollMoreLeadResults(
    resultsResponse,
    formType = FORM_TYPES.PRIMARY
  ) {
    if (resultsResponse) {
      const globalResultsKey =
        formType === FORM_TYPES.PRIMARY
          ? 'leadSubmitResults'
          : 'secondaryLeadSubmitResults';
      updateMicroPortalData({ [globalResultsKey]: resultsResponse });
    }

    return resultsResponse?.isWaitMoreResults;
  }

  /**
   * @param {Object} formValues - current form state
   * @param {Object} fieldNameMap - map of all field names
   * @param {Object} formConfigs - the questionnaire
   * @param {Object} formState - the full questionnaire state object
   * @param {String} formType - the type of form we are submitting
   */
  function onFormSubmit(
    formValues,
    fieldNameMap,
    formConfigs,
    formState,
    formType = FORM_TYPES.PRIMARY
  ) {
    const {
      allQuestionsInForm,
      formStatus: { dynamicOptions },
    } = formState;

    const disclaimerText =
      formValues[
        allQuestionsInForm[MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]?.name
      ]?.dynamicDisclaimerText;

    const { remainingSchoolsCount } =
      dynamicOptions[MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION] || {};

    if (!disclaimerText) {
      LogError('Form was submitted without disclaimerText.');
    }

    const leadSubmitRequest = () =>
      submitLead(formValues, fieldNameMap, {
        ...formConfigs,
        leadSubmitEndpoint: LEAD_SUBMIT_ENDPOINT_MAP[formType],
        disclaimerText,
      });

    const globalHelperKey =
      formType === FORM_TYPES.PRIMARY
        ? 'primaryFormSubmitPollingHelper'
        : 'secondaryFormSubmitPollingHelper';

    updateMicroPortalData({
      [globalHelperKey]: requestWithTransaction(leadSubmitRequest, {
        transactionKey,
        shouldPollFunc: (results) =>
          shouldPollMoreLeadResults(results, formType),
        resultsRequest: getMicroPortalLeadSubmitResults,
      }),
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        let uri = clickPortalLikePageUri;

        if (remainingSchoolsCount > 0) {
          uri = additionalLeadsPageUri;
        }

        router.push(uri).then(() => {
          resolve();
        });
      }, onSubmitPushMsDelay);
    });
  }

  /**
   * @param {Object} payload
   * @param {String} schoolCode current site's school code
   * @param {String} variant - the questionnaire version we are showing user, A/B test
   */
  function onOptionsRequest(payload, schoolCode, variant) {
    const optionPromises = [];

    // If we need School options call this separately
    if (
      payload.requestedOptions.includes(MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION)
    ) {
      const schoolSelectionPayload = {
        ...payload,
        maxSchools: 4,
        leadEvalToken: formStatus?.leadEvalToken,
        pageTaxonomyValues: {
          categories: taxonomyValues?.categories?.[0],
          degrees: taxonomyValues?.degrees?.[0],
          parentCategories: taxonomyValues?.parentCategories?.[0],
        },
      };
      schoolSelectionPayload.requestedOptions = [
        MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION,
      ];
      optionPromises.push(
        debouncedSchoolSelectionRequestOptions(
          schoolSelectionPayload,
          schoolCode,
          variant,
          MICRO_PORTAL_ENDPOINTS.SCHOOL_OPTIONS_ENDPOINT
        )
      );

      // We are just priming cache
      getNewLeadSubmitBatchId();
    }

    // Its possible that the TCPA question is also called with other regular questions
    const nonSchoolSelectionQuestions = payload.requestedOptions.filter(
      (questionId) => questionId !== MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION
    );
    if (nonSchoolSelectionQuestions.length) {
      const nonSchoolSelectionPayload = { ...payload };
      nonSchoolSelectionPayload.requestedOptions = nonSchoolSelectionQuestions;
      optionPromises.push(
        debouncedRequestOptions(
          nonSchoolSelectionPayload,
          schoolCode,
          variant,
          MICRO_PORTAL_ENDPOINTS.QUESTION_OPTIONS_ENDPOINT
        )
      );
    }

    return Promise.all(optionPromises).then((responses) => {
      return responses.reduce(
        (allOptions, currentOptions) => ({ ...allOptions, ...currentOptions }),
        {}
      );
    });
  }

  /**
   * @summary use this to log current progress for user's session so we can get it back in the getProfile API
   * @param {Object} formValues - current form state
   * @param {Object} fieldNameMap - map of all field names
   * @param {String} formConfigs - the questionnaire configuration
   * @param {Object} linkedSessionFormValues - form values that could have come from another session
   * @param {number} lastQuestionAnswered - id of the last question answered
   */
  function onLogProgress(
    formValues,
    fieldNameMap,
    formConfigs,
    linkedSessionFormValues,
    lastQuestionAnswered
  ) {
    return logProgress(
      formValues,
      fieldNameMap,
      formConfigs,
      linkedSessionFormValues,
      lastQuestionAnswered,
      MICRO_PORTAL_ENDPOINTS.LOG_PROGRESS_ENDPOINT
    );
  }

  /**
   * @summary start polling for lead submit results
   * @param {String} formType - the type of form we are polling results for
   */
  async function startLeadSubmitPolling(formType = FORM_TYPES.PRIMARY) {
    if (!FORM_TID_MAP[formType]) {
      throw new Error(`Invalid form type: ${formType}`);
    }

    // if we have a polling helper, use that to start polling. This is the case when we did a client side redirect
    const pollingHelper =
      formType === FORM_TYPES.PRIMARY
        ? primaryFormSubmitPollingHelper
        : secondaryFormSubmitPollingHelper;
    if (pollingHelper) {
      const { startPolling, getTransactionId } = await pollingHelper;
      sessionStorage.setItem(FORM_TID_MAP[formType], getTransactionId());
      return startPolling();
    }

    // if we have a transaction id stored in session storage, use that to start polling
    const storedTransactionId = sessionStorage.getItem(FORM_TID_MAP[formType]);
    if (storedTransactionId) {
      const { startPolling } = getPollingHelper({
        shouldPollFunc: (results) =>
          shouldPollMoreLeadResults(results, formType),
        transactionId: storedTransactionId,
        resultsRequest: getMicroPortalLeadSubmitResults,
      });

      return startPolling().catch((error) => {
        LogError('Failed to start polling for lead submit results', { error });
      });
    }

    /**
     * If we have no transaction id, we can't start polling.
     * This could happen if the user refreshes the page before the
     * initial lead submit request has completed or if we change from
     * client side routing to page redirect
     */
    LogError('Micro portal results page loaded with no Lead Submit Results');
    return Promise.reject();
  }

  return {
    onLogProgress,
    onOptionsRequest,
    onFormSubmit,
    startLeadSubmitPolling,
    /* for testing */
    shouldPollMoreLeadResults,
  };
}
