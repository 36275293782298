import { useEffect, useReducer } from 'react';
import { LogError, LogWarning } from 'utils/logging';
import {
  MultiSchoolSelectAction,
  MultiSchoolSelectActionTypes,
  MultiSchoolSelectionState,
  School,
  UseMultiSchoolSelect,
} from 'types';
import { FIELD_NAMES } from 'consts';

const {
  EXPAND_CARD,
  SKIP_CARD,
  ACCEPT_TERMS,
  TOGGLE_DETAILS,
  CLOSE_CARD,
  UPDATE_PROGRAM,
  EXPAND_NEXT_CARD,
  INITIALIZE,
} = MultiSchoolSelectActionTypes;

const initialState: MultiSchoolSelectionState = {};
let isInitialized = false;

/**
 * Updates the state of a MultiSchoolSelect component based on the action that
 * is passed in.
 */
function reducer(
  state: MultiSchoolSelectionState,
  action: MultiSchoolSelectAction
): MultiSchoolSelectionState {
  const { type, payload } = action;
  const { id = '', program, schools, handleFormFieldChange } = payload;

  const updatedState = { ...state };
  const schoolReferenced = updatedState[id];

  if (!isInitialized && type !== INITIALIZE) {
    LogError(
      `MultiSchoolSelectionState not initialized. Please initialize then dispatch "${type}"`
    );
  }

  switch (type) {
    case INITIALIZE: {
      if (!schools || !schools.length) {
        LogError('MultiSchoolSelectionState initialized without schools');
        return state;
      }

      isInitialized = true;

      const initializedState = schools.reduce(
        (acc: MultiSchoolSelectionState, school: School) => {
          acc[school.id] = {
            isSelected: true,
            isExpanded: false,
            isAccepted: false,
            isSkipped: false,
            isSubmitted: false,
            isShowingDetails: false,
            program: null,
            schoolInfo: school,
          };
          return acc;
        },
        {}
      );

      handleFormFieldChange?.(initializedState);
      return initializedState;
    }

    case SKIP_CARD: {
      schoolReferenced.isSkipped = true;
      schoolReferenced.isExpanded = false;
      handleFormFieldChange?.(updatedState);
      return updatedState;
    }

    case EXPAND_CARD: {
      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Only expand if the card is selected and not skipped
      if (schoolReferenced.isSelected) {
        schoolReferenced.isExpanded = true;
      }

      return updatedState;
    }

    case EXPAND_NEXT_CARD: {
      const nextSchoolId = Object.keys(state).find(
        (schoolId) =>
          state[schoolId].isSelected &&
          !state[schoolId].isAccepted &&
          !state[schoolId].isSkipped &&
          !state[schoolId].isExpanded
      );

      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Expand the next eligible card
      if (nextSchoolId) {
        updatedState[nextSchoolId].isExpanded = true;
      }

      return updatedState;
    }

    case ACCEPT_TERMS: {
      const nextSelectedSchoolId = Object.keys(state).find(
        (schoolId) =>
          state[schoolId].isSelected &&
          !state[schoolId].isAccepted &&
          !state[schoolId].isSkipped &&
          schoolId !== id
      );

      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Update the state for the current school
      schoolReferenced.isAccepted = true;
      schoolReferenced.isExpanded = false;
      schoolReferenced.isSubmitted = true;

      // If there's a next school to expand, update its state
      if (nextSelectedSchoolId) {
        updatedState[nextSelectedSchoolId].isExpanded = true;
      }

      handleFormFieldChange?.(updatedState);
      return updatedState;
    }

    case TOGGLE_DETAILS: {
      schoolReferenced.isShowingDetails = !schoolReferenced.isShowingDetails;
      return updatedState;
    }

    case CLOSE_CARD: {
      schoolReferenced.isExpanded = false;
      schoolReferenced.isShowingDetails = false;
      return updatedState;
    }

    case UPDATE_PROGRAM: {
      schoolReferenced.program = program || null;
      return updatedState;
    }

    default:
      LogWarning(`MultiSchoolSelection Unknown action type: "${type}"`);
      return state;
  }
}

export function useMultiSchoolSelect(
  schools: School[],
  fieldName: FIELD_NAMES,
  handleFormFieldChange: (updatedFieldValue: MultiSchoolSelectionState) => void
): UseMultiSchoolSelect {
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleSelectSchool(id: string): void {
    dispatch({ type: EXPAND_CARD, payload: { id } });
  }

  function handleSkipSchool(id: string): void {
    dispatch({ type: SKIP_CARD, payload: { id, handleFormFieldChange } });
    dispatch({ type: EXPAND_NEXT_CARD, payload: {} });
  }

  function handleAcceptTerms(id: string): void {
    dispatch({ type: ACCEPT_TERMS, payload: { id, handleFormFieldChange } });
    // TODO [T1-11708]: getbatchId.then -> submitSchoolCard.catch -> log error
  }

  function handleNextClick(): void {
    dispatch({ type: EXPAND_NEXT_CARD, payload: {} });
  }

  function handleToggleDetails(id: string): void {
    dispatch({ type: TOGGLE_DETAILS, payload: { id } });
  }

  function handleCloseCard(id: string): void {
    dispatch({ type: CLOSE_CARD, payload: { id } });
  }

  function handleProgramChange(id: string, program: string): void {
    dispatch({ type: UPDATE_PROGRAM, payload: { id, program } });
  }

  function initializeState(initialSchools: School[]): void {
    dispatch({
      type: INITIALIZE,
      payload: { schools: initialSchools, handleFormFieldChange },
    });
  }

  useEffect(() => {
    initializeState(schools);
  }, [schools]);

  return {
    state,
    handleSelectSchool,
    handleSkipSchool,
    handleAcceptTerms,
    handleNextClick,
    handleToggleDetails,
    handleCloseCard,
    handleProgramChange,
    initializeState,
  };
}
