/* eslint-disable no-useless-escape */
import {
  FieldValidationArguments,
  FieldValidationFunctionSignature,
  FieldValidationResult,
  MultiSchoolSelectionState,
} from 'types';
import { getDataForZip, validatePhone } from 'app-requests/triadmsRequests';
import { DEFAULT_SELECT_VALUE, FIELD_NAMES, VALIDATION_TYPES } from 'consts';
import { isValidEmail, isValidZip } from './formValuesUtils';

const { PRIMARY_PHONE_TYPE } = FIELD_NAMES;

/**
 * @summary This is used to validate zip code by calling our backend API
 */
export function validateZip(
  args: FieldValidationArguments<string>
): FieldValidationResult | Promise<FieldValidationResult> {
  const { value, name, errorMessage } = args;
  if (!value) return Promise.resolve({});
  if (!isValidZip(value)) return { [name]: 'Invalid Zip Code' };

  return getDataForZip({ zip: value }).then(
    ({ isValid }: { isValid: boolean }) => {
      if (!isValid) {
        return {
          [name]: errorMessage || 'Invalid Zip Code',
        };
      }
      return {};
    }
  );
}

/**
 * @summary This is used to validate Phone Numbers by calling our backend API
 */
function validatePhoneNumber(
  args: FieldValidationArguments<string>
): FieldValidationResult | Promise<FieldValidationResult> {
  const { value, name, formValues } = args;
  if (!value) return Promise.resolve({});

  // needs to be 10 digest
  if (value.replace(/-/g, '').length !== 10)
    return Promise.resolve({ [name]: 'Invalid Phone Number' });

  function hasValue(fieldName: FIELD_NAMES): boolean {
    return Boolean(
      formValues &&
        formValues[fieldName] &&
        formValues[fieldName].value !== DEFAULT_SELECT_VALUE.value
    );
  }

  // always needs primary phone type
  if (!hasValue(PRIMARY_PHONE_TYPE))
    return Promise.resolve({
      [FIELD_NAMES.PRIMARY_PHONE_TYPE]: 'Please select a phone type.',
    });

  return validatePhone(value).then(({ isValid }: { isValid: boolean }) => {
    if (isValid) {
      return Promise.resolve({});
    }

    return { [name]: `We could not validate "${value}"` };
  });
}

/**
 * @summary This is used to validate Emails
 */
export function validateEmail(
  args: FieldValidationArguments<string>
): FieldValidationResult | Promise<FieldValidationResult> {
  const { value, name, errorMessage } = args;
  if (!isValidEmail(value)) {
    return Promise.resolve({
      [name]: errorMessage || 'Please enter a correct Email Address Format.',
    });
  }

  return Promise.resolve({});
}

/**
 * @summary This is used to validate Street Address
 * @see https://triadms.atlassian.net/browse/T1-5352
 */
export function validateStreet(
  args: FieldValidationArguments<string>
): FieldValidationResult | Promise<FieldValidationResult> {
  const { value, name, errorMessage } = args;

  const errorObj = { [name]: errorMessage || 'Invalid Street' };
  const hasNumbersRegex = /\d+/g;
  const hasCharRegex = /[a-zA-Z]+/g;
  const hasProperSpacing = /^\s*\S+(?:\s+\S+){1}/;

  if (!hasNumbersRegex.test(value)) {
    return Promise.resolve(errorObj);
  }
  if (!hasCharRegex.test(value)) {
    return Promise.resolve(errorObj);
  }
  if (!hasProperSpacing.test(value)) {
    return Promise.resolve(errorObj);
  }

  return Promise.resolve({});
}

/**
 * @summary This is used to validate MultiSchoolSelectionState
 *          for having at least one school accepted or skipped
 */
function validateSchoolSkipOrSubmit(
  args: FieldValidationArguments<MultiSchoolSelectionState>
): FieldValidationResult {
  const { value, name, errorMessage } = args;
  const errorObj = {
    [name]: errorMessage || 'Please accept the terms and conditions.',
  };

  const hasUnAcknowledgedSchools = Object.values(value).some(
    (schoolSelectionState) =>
      !schoolSelectionState.isAccepted && !schoolSelectionState.isSkipped
  );

  if (hasUnAcknowledgedSchools) {
    return errorObj;
  }

  return {};
}

const FIELD_VALIDATION_MAP: Record<
  VALIDATION_TYPES,
  FieldValidationFunctionSignature<never>
> = {
  [VALIDATION_TYPES.ZIP]: validateZip,
  [VALIDATION_TYPES.EMAIL]: validateEmail,
  [VALIDATION_TYPES.PHONE]: validatePhoneNumber,
  [VALIDATION_TYPES.STREET]: validateStreet,
  [VALIDATION_TYPES.SCHOOL_SKIP_OR_SUBMIT]: validateSchoolSkipOrSubmit,
};

export default FIELD_VALIDATION_MAP;
